<template>
    <div class='out_storage'>
         <!-- 下拉按钮弹出框 -->
        <a-modal 
            :title="currentDrawdown.title"
            ok-text="确认" 
            cancel-text="取消"
            :confirm-loading="drawdownLoading"
            @ok="drawdownConfirm"
            v-model:visible="showDrawdown">
                <h2 v-if="currentDrawdown.text">
                    {{currentDrawdown.text}}
                </h2>
                <m-dialog-content 
                    v-else
                    :readOnly="false" 
                    :formItems="currentDrawdown.info">
                </m-dialog-content>
         </a-modal>
          <!-- 详情弹出框 -->
        <a-modal 
            :title="currentDrawdown.title"
            ok-text="确认" 
            cancel-text="取消"
            @ok="showDetail = false"
            :confirm-loading="drawdownLoading"
            v-model:visible="showDetail">
                <m-dialog-content 
                    :readOnly="true" 
                    :formItems="machinDetail">
                </m-dialog-content>
         </a-modal>
         <!-- 搜索框 -->
         <m-form 
            :formItems="formItems"
            @fileevent="batchSetting($event)" 
            @search="loadData(1)" ></m-form>
        <!-- 表格数据 -->
        <m-table 
        :loading="loading" 
        :columns="columns" 
        :data="tableList" 
        :buttons="buttons"
        :totals="total"
        :currentPage="currentPage"
        @pageChange="loadData($event)"
        @dropEVent="dropEVent($event)"
        @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { toRefs, reactive } from '@vue/reactivity'
import { geneIndex, showMessage,} from '@/utils/util'
import { 
    isValid, 
    machineList, 
    machineDetail,
    modifyPaymentParm,
    machineSetPledge,
    operatorList,
    machineRefreshDrawBillStatus,
    machineSetReplacementLimit,
    machineSetTicketAmount,
    machineSetUnitAmount,
    checkPaymentParme,
    modifyIotInfo,
    machineSetDataCardNo,
    machinSetTicketType,
    machineSetAgentId,
    machineSetStoreId,
    machineSetReplacementerId,
    setUnitTicketNumber,
    informAgentBindAgent,
    setPrams,
    upgradeFirmware,
    machineUnbind,
    setLED,
    setSound, 
    copyRight,
    machineTicketRevise,
    } from '../../utils/api'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
        const state= reactive({
            //  table列表
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index', width: 60},
                 {title: '设备编号', dataIndex: 'no', key: 'no'},
                 {title: '设备状态', dataIndex: 'statusText', key: 'statusText', width: 120},
                 {title: '设备工作状态', dataIndex: 'workStatusText', key: 'workStatusText', width: 120},
                 {title: '代理', dataIndex: 'agentId', key: 'agentId'},
                 {title: '运营人员', dataIndex: 'operatorName', key: 'operatorName'},
                 {title: '省', dataIndex: 'shopProvince', key: 'shopProvince'},
                 {title: '票种', dataIndex: 'ticketTypeText', key: 'ticketTypeText'},
                 {title: '总额度', dataIndex: 'ticketTotalAmount', key: 'ticketTotalAmount'},
                 {title: '余票张数', dataIndex: 'ticketBalanceNumber', key: 'ticketBalanceNumber'},
                 {title: '工作参数配置', dataIndex: 'parmConfigFinishText', key: 'parmConfigFinishText'},
                 {title: '操作',  dataIndex: 'manage', key: 'name',  slots: {customRender: 'actions'}},
             ],
             // 设备详情
              // 详情弹出框
             machinDetail: [
                 {label: '设备编号', value: '', type: 'input', name: 'no'},
                 {label: '识别名称', value: '', type: 'input', name: 'chipDeviceName'},
                 {label: '产品Key', value: '', type: 'input', name: 'chipProductKey'},
                 {label: '设备状态', value: '', type: 'input', name: 'statusText'},
                 {label: '工作状态', value: '', type: 'input', name: 'workStatusText'},
                 {label: '工作参数配置', value: '', type: 'input', name: 'parmConfigFinishText'},
                 {label: '固件版本号', value: '', type: 'input', name: 'firmwareVersion'},
                 {label: '流量卡卡号', value: '', type: 'input', name: 'dataCardNo'},
                 {label: '票面金额(元)', value: '', type: 'input', name: 'ticketUnitAmount'},
                 {label: '票总额度(张数)', value: '', type: 'input', name: 'ticketTotalNumber'},
                 {label: '票总额度(金额)', value: '', type: 'input', name: 'ticketTotalAmount'},
                 {label: '余票张数', value: '', type: 'input', name: 'ticketBalanceNumber'},
                 {label: '余票额度(元)', value: '', type: 'input', name: 'ticketBalanceAmount'},
                 {label: '可补票张数', value: '', type: 'input', name: 'ticketFillableNumber'},
                 {label: '可补票额度(元)', value: '', type: 'input', name: 'ticketFillableAmount'},
                 {label: '补票中张数', value: '', type: 'input', name: 'ticketFillingNumber'},
                 {label: '补票中额度(元)', value: '', type: 'input', name: 'ticketFillingAmount'},
                 {label: '小包票张数', value: '', type: 'input', name: 'ticketFillUnitPackNumber'},
                 {label: '自动补票触发张数', value: '', type: 'input', name: 'autoTriggerFillThresholdNumber'},
                 {label: '设备押金(元)', value: '', type: 'input', name: 'deviceDeposit'},
                 {label: '支付code', value: '', type: 'input', name: 'paymentProviderCode'},
                 {label: '经度', value: '', type: 'input', name: 'longitude'},
                 {label: '纬度', value: '', type: 'input', name: 'latitude'},
                 {label: '店铺Id', value: '', type: 'input', name: 'shopId'},
                 {label: '店铺名', value: '', type: 'input', name: 'shopName'},
                 {label: '店铺-省', value: '', type: 'input', name: 'shopProvince'},
                 {label: '店铺-市', value: '', type: 'input', name: 'shopCity'},
                 {label: '店铺-区县', value: '', type: 'input', name: 'shopCounty'},
                 {label: '详细地址', value: '', type: 'input', name: 'shopAddress'},
                 {label: '店铺-经度', value: '', type: 'input', name: 'shopLongitude'},
                 {label: '店铺-纬度', value: '', type: 'input', name: 'shopLatitude'},
                 {label: '代理id', value: '', type: 'input', name: 'agentId'},
                 {label: '代理名称', value: '', type: 'input', name: 'agentName'},
                 {label: '运营id', value: '', type: 'input', name: 'operatorId'},
                 {label: '运营姓名', value: '', type: 'input', name: 'operatorName'},
                //  {label: '', value: '', type: 'input', name: ''},
                //  {label: '', value: '', type: 'input', name: ''},
                //  {label: '', value: '', type: 'input', name: ''},
                ],
              // 表单列表
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             loading: true,
              // 条件搜索表单
             formItems: [
                 {type: 'selector', label: '工作参数配置', key: 'value',options: [{label: '已完成', value: true}, {label: '未完成', value: false}], value: undefined},
                 {type: 'input', label: '设备编号', value: undefined},
                 {type: 'selector', label: '设备状态', key: 'value', options: [{label: '已入库', value: 0}, {label: '配送中', value: 3}, {label: '正常', value: 10}, {label:'不可用', value:99}]},
                 {type: 'selector', label: '设备工作状态', options: ['脱机', '在线空闲', '在线忙', '异常']},
                 {type: 'input', label: '代理ID', value: undefined},
                 {type: 'button', label: '搜索', eventName: 'search'},
                 {type: 'file', label: '批量设置', eventName: 'batchSetting'}
             ],
              // table里的操作按钮
             buttons: [
                 {text: '详情', eventName: 'detail'},
                 {text: '更多', eventName: 'more' , type: 'dropDown', 
                    drops: [
                        {text: '分配运营人员', eventName: 'assignOperator', prop: 'dropOperators'},
                        {text: '设置小包票张数', eventName: 'setUnitTicketNumber', prop: 'unitTicketNumber'},
                        {text: '恢复出票', eventName: 'reSet', prop: 'restoreDrawer', isConfirm: true}, 
                        {text: '设置设备押金', eventName: 'setMachineCash', prop: 'machineCashPldge',},
                        {text: '设置自动补票触发张数', eventName: 'triggleFill', prop: 'triggleFill',},
                        {text: '设置票总额度', eventName: 'setTicketNum', prop: 'ticketLimit',},
                        {text: '票面额度', eventName: 'setTicketUnit', prop: 'ticketUnitAmount'},
                        {text: '新大陆支付参数', eventName: 'newMainlandPrams', prop: 'newMainLandParamInfo'},
                        {text: '检测支付参数', eventName: 'checkPaymentParm', prop: 'checkPaymentParm'},
                        {text: '配置物联网三元组', eventName: 'assignWLW', prop: 'webOfThingsTripleInfo',},
                        {text: '设置数据卡卡号', eventName: 'setDataCardNo', prop: 'dataCardNo'},
                        {text: '设置票类型', eventName: 'setTicketType', prop: 'ticketTypes'},
                        {text: '设置代理商Id', eventName: 'setAgentId', prop: 'AgentIds'},
                        {text: '设置店铺Id', eventName: 'setStoreId', prop: 'storeId'},
                        {text: '代理端设置代理商Id', eventName: 'agentBindAgentId', prop: 'agentBindAgentId'},
                        {text: '固件升级', eventName: 'firmwareUpgrade', prop: 'firmwareUpgrade'},
                        {text: '设备解绑', eventName: 'unbind', prop: 'unbind'},
                        {text: '上报版本号', eventName: 'uploadCopyRight', prop: 'uploadCopyRight'},
                        {text: '设置LED灯', eventName: 'lightSetting', prop: 'lightSetting'},
                        {text: '设置声音', eventName: 'soundSetting', prop: 'soundSetting'},
                        {text: '设备冲正', eventName: 'ticketRevise', prop: 'ticketRevise'},
                    ]}
             ],
             // 当前ID
             currentID: undefined,
             // 上架
            //  putaway: { title: '', prop: 'putaway', text: '确认上架？' },
              // 手动通知代理端设置
             agentBindAgentId: { title: '', prop: 'agentBindAgentId', text: '是否手动调用代理端服务接口绑定代理商?' },
             // 恢复出票
             restoreDrawer: {title: '', prop: 'restoreDrawer', text: '确认恢复出票？'},
             // 上报设备号
             uploadCopyRight: {title: '', prop: 'uploadCopyRight', text: '确认上报设备号？'},
             // 固件升级
             firmwareUpgrade: {title: '', prop: 'firmwareUpgrade', text: '确认升级固件？'},
            // 设备解绑
            unbind: {title: '', prop: 'unbind', text: '确认解绑设备？'},
             // 检测支付参数
             checkPaymentParm: {title: '', prop: 'checkPaymentParm', text: '点击检测支付参数是否完整？'},
             // 运营人员
             dropOperators:{
                title: '运营人员',
                prop: 'dropOperators',
                info: [
                    {label: '运营人员', value: '', type: 'selector', key: 'value', name: 'operatorId', options: []},
                ]
             },
             // 设置LED灯
             lightSetting:{
                title: '设置LED灯',
                prop: 'lightSetting',
                info: [
                    {label: 'LED灯设置', value: '', type: 'selector', key: 'value', name: 'ledStatus', options: [{value: 1, labelName: '开启'}, {value: 0, labelName: '关闭'}]},
                ]
             },
               // 声音设置
            soundSetting: {
                 title: '机器声音设置',
                 prop: 'soundSetting',
                 info: [{label: '音量', value: '', type: 'input', name: 'volumeLevel'},]
             },
             // 设备冲正
             ticketRevise: {
                title: '机器冲正',
                 prop: 'ticketRevise',
                 info: [
                    {label: '余票张数', value: '', type: 'input', name: 'ticketBalanceNumber'},
                    {label: '冲正类型', value: '', type: 'selector', key: 'value', name: 'recordType', options: [{value: '0', labelName: '冲正'},{value: '1', labelName: '退票'},{value: '2', labelName: '换机'},]},
                    {label: '冲正备注', value: '', type: 'input', name: 'remark'},
                ]
             },
             // 票类型
             ticketTypes: {
                title: '票类型',
                prop: 'ticketTypes',
                info: [
                    {name: 'ticketType', label: '票类型', value: 'TC', type: 'selector', key: 'value', options: [{labelName: '体彩', value: 'TC'}, {labelName: '福彩', value: 'FC'}]},
                ]
             },
             // 自动补票触发额度
             triggleFill: {
                 title: '自动补票触发额度',
                 prop: 'triggleFill',
                 info: [{label: '额度', value: '', type: 'input', name: 'autoTriggerFillThreshold'},]
             },
             // 设置小包票张数
             unitTicketNumber: {
                 title: '小包装张数',
                 prop: 'unitTicketNumber',
                 info: [{label: '张数', value: '', type: 'input', name: 'ticketFillUnitPackNumber'}]
             },
             // 数据卡卡号
             dataCardNo: {
                title: '数据卡卡号',
                prop: 'dataCardNo',
                info: [{label: '卡号', value: '', type: 'input', name: 'dataCardNo'},]
             },
             // 代理商Id
             AgentIds: {
                title: '代理商Id',
                prop: 'AgentIds',
                info: [{label: 'Id', value: '', type: 'input', name: 'agencyId'},]
             },
             // 店铺Id
            storeId:{
                title: '店铺Id',
                prop: 'storeId',
                info: [{label: 'Id', value: '', type: 'input', name: 'shopId'},]
             },
             // 票面金额
            ticketUnitAmount: {
                 title: '票面金额',
                 prop: 'ticketUnitAmount',
                 info: [{label: '票面金额', value: '', type: 'input', name: 'ticketUnitAmount'},]
            },
             // 物联网三元组
             webOfThingsTripleInfo:{
                 title: '物联网三元组',
                 prop: 'webOfThingsTripleInfo',
                 info: [
                //  {label: '设备ID', value: '', type: 'input', name: 'id'},
                 {label: '识别名称', value: '', type: 'input', name: 'chipDeviceName'},
                 {label: '产品Key', value: '', type: 'input', name: 'chipProductKey'},
                ]
             } ,
                // 新大陆支付参数
            newMainLandParamInfo: {
                title: '新大陆支付参数',
                prop: 'newMainLandParamInfo',
                info: [
                    // {label: '设备ID', value: '', type: 'input', name: 'id'},
                    {label: 'trmNo参数', value: '', type: 'input', name: 'trmNo'},
                    {label: 'posKey参数', value: '', type: 'input', name: 'posKey'},
                ]
            } ,
            // 设备押金
            machineCashPldge: {
                title: '设备押金',
                prop: 'machineCashPldge',
                info: [
                    {label: '设备押金', value: '', type: 'input', name: 'deviceDeposit'}
                ]
            },
            // 补票人员Id
            replacementerId: {
                title: '补票人员Id',
                prop: 'replacementerId',
                info: [
                    {label: 'Id', value: '', type: 'input', name: 'deviceDeposit'}
                ]
            },
            // 票额度
            ticketLimit: {
                title: '票总额度',
                prop: 'ticketLimit',
                info: [
                    {label: '总额度(张)', value: '', type: 'input', name: 'ticketTotalNumber'},
                    {label: '总额度(元)', value: '', type: 'input', name: 'ticketTotalAmount'}
                ]
            },

            operators: [],
            currentDrawdown: {},
            currentDrop: {},
            drawdownLoading: false,
            showDrawdown: false,
            currentTableItem: {}, // 当前点击的table项
            showDetail: false
        })
         // 初始化显示详情
        function initDetailData(data) {
            state.currentTableItem = data
            const forms = state.machinDetail
            const ss = data.status 
            data.statusText = ss == 0 ? '已入库'
                                : ss == 3 ? '配送中'
                                : ss == 10 ? '正常'
                                : ss == 99 ? '不可用' : '未知状态'
            data.parmConfigFinishText = data.parmConfigFinish ? '已完成' : '未完成'
            const ws = data.workStatus 
            data.workStatusText = ws == 'offline' ? '机器未联机'
                                : ws == 'idle' ? '在线空闲'
                                : ws == 'busy' ? '在线忙'
                                : ws == 'empty' ? '票售罄'
                                : ws == 'error' ? '错误' : '未知状态'
            forms.forEach(ele => {
                ele.value = data[ele.name]
            })
        }
         // 机器状态Map
        function machineStatus(status) {
            // 文字对应数字
            const textMap = new Map()
            // 数字对应文字
            const numMap = new Map()
            const arr = [
                ['已入库', '0'],
                ['配送中', '3'],
                ['正常', '10'],
                ['不可用', '99'],
            ]
            arr.forEach(ele => {
                textMap.set(ele[0], ele[1])
                numMap.set(ele[1], ele[0])
            })

            if(textMap.has(status)) {
                return textMap.get(status)
            } else {
                return numMap.get(status)
            }
        }
         // 机器工作状态
        function machineWorkStatus(status) {
            const textMap = new Map()
            textMap.set('offline', '脱机')
            textMap.set('idle', '在线空闲')
            textMap.set('busy', '在线忙')
            textMap.set('error', '异常')

            const letterMap = new Map()
            letterMap.set('脱机', 'offline')
            letterMap.set('在线空闲', 'idle')
            letterMap.set('在线忙', 'busy')
            letterMap.set('异常', 'error')

            if (textMap.has(status)) {
                return textMap.get(status)
            } else {
                return letterMap.get(status)
            }
        }
        // 初始化列表显示文字
        function initTableText(data) {
            data.forEach((ele) => {
                ele.statusText = machineStatus(ele.status)
                ele.workStatusText = machineWorkStatus(ele.workStatus)
            })
            return data
        }
        // 查看设备详情
        async function detail(event) {
            try {
                const result = await machineDetail({id: event.id})
                if (isValid(result)) {
                    // state.popFormsType = 0
                    // state.tempForms = state.popForms
                    initDetailData(result.data)
                    state.showDetail = true
                }
            } catch(e) {
                console.error(e)
            }
        }
         // 获取设备列表
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const forms = state.formItems
                const params = {
                    parmConfigFinish: forms[0].value,
                    no: forms[1].value,
                    status: forms[2].value,
                    workStatus: machineWorkStatus(forms[3].value),
                    agentId: forms[4].value,
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                }
                const result = await machineList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = initTableText(result.data.records || [])
                    // addID(state.tableList)
                    state.tableList = geneIndex(state.tableList, state.pageSize, state.currentPage)
                    state.tableList.forEach((ele) => {
                       const t = ele.ticketType
                       const c = ele.parmConfigFinish
                       ele.ticketTypeText = t == 'FC' ? '福彩' : '体彩'
                       ele.parmConfigFinishText = c ? '已完成' : '未完成'
                    })
                }
                if (isValid(result)) {
                    console.log(result)
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        // 初始化弹出框显示值
        function initPopValue() {
            const {info} = state.currentDrawdown
            const value = state.currentTableItem
            if (info) {
                info.forEach(ele => {
                    ele.value = value[ele.name]
                    })
            }
        }
        // 获取运营人员信息
        async function getOperaters() {
            try {
                const params = {
                    currentPage: 1,
                    pageSize: 1000,
                    status: 10
                }
                const info = state.dropOperators.info
                const result = await operatorList(params)
                if (result.code == 0) {
                    state.operators = []
                    result.data.records.forEach(ele => {
                        state.operators.push({value: ele.id, labelName: ele.name})
                    })
                    info[0].options = state.operators

                }
            } catch(e) {
                console.error(e)
            }
        }
        getOperaters()
        // drowdown点击事件
        function dropEVent(value) {
            const drops = state.buttons[1].drops
            const currentDrop = drops.filter(ele => ele.eventName == value.eventName)[0]
            state.currentDrop = currentDrop
            state.currentTableItem = value.value
            state.currentID = value.value.id
            if (currentDrop?.prop) {
                state.currentDrawdown = state[currentDrop.prop]
                initPopValue()
                state.showDrawdown = true
            }
        }
        // 获取参数
        function getParams() {
            const id = state.currentTableItem.id 
            const items = state.currentDrawdown.info
            return {id, items, ...state.currentDrawdown, ...state.currentTableItem}
        }
        // 设置成功
        function setSuccess(result, text = '设置成功') {
            if(result.code == 0) {
                showMessage(text, 'success')
                state.showDrawdown = false
                loadData()
            }
        }
        // 手动通知代理端绑定代理商
        async function agentBindAgentId() {
            try {
                const {id} = getParams()
                const result = await informAgentBindAgent({id})
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 升级固件
        async function firmwareUpgrade() {
            try {
                const { no } = getParams()
                const result = await upgradeFirmware(no)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设备冲正
        async function ticketRevise() {
            try {
                const {no, items} = getParams()
                const result = await machineTicketRevise({
                    deviceNo: no,
                    number: items[0].value,
                    recordType: items[1].value,
                    remark: items[2].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置代理商Id
        async function setAgentId() {
            try {
                const {id, items} = getParams()
                const result = await machineSetAgentId({
                    id,
                    agentId: items[0].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置店铺Id
        async function setStoreId() {
            try {
                const {id, items} = getParams()
                const result = await machineSetStoreId({
                    id,
                    shopId: items[0].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置票类型
        async function setTicketType() {
            try {
                const {id, items} = getParams()
                const result = await machinSetTicketType({
                    id,
                    ticketType: items[0].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置配送人员Id
        async function setReplacementerId() {
            try {
                const {id, items} = getParams()
                const result = await machineSetReplacementerId({
                    id,
                    operatorId: items[0].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 配置物联网三元组
        async function webOfThingsTripleInfo() {
            try {
                const {id, items} = getParams()
                const params = {
                    id: id,
                    chipDeviceName: items[0].value,
                    chipProductKey: items[1].value
                }
                const result = await modifyIotInfo(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置新大陆参数
        async function newMainLandParamInfo() {
            try {
                const {id, items} = getParams()
                const params = {
                    id,
                    paymentProviderCode: 'xdl',
                    trmNo: items[0].value,
                    posKey: items[1].value
                }
                const result = await modifyPaymentParm(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 分配运营人员
        async function dropOperators() {
            try {
                const {id, items} = getParams()
                // const operatorId = state.operators.filter(ele => ele.name == items[0].value)
                const pararms = {
                    id: id,
                    operatorId: items[0].value
                }
                const result = await machineSetReplacementerId(pararms)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置设备押金
        async function machineCashPldge() {
            try {
                const {id, items} = getParams()
                const params = {
                    id, 
                    deviceDeposit: items[0].value
                }
                const result = await machineSetPledge(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置自动补票出发额度
        async function triggleFill() {
            try {
                const {id, items} = getParams()
                const params = {
                    id,
                    autoTriggerFillThresholdNumber: items[0].value
                }
                const result = await machineSetReplacementLimit(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置票面金额
        async function setTicketUnit() {
            try {
                const {id, items} = getParams()
                const params = {
                    id,
                    ticketUnitAmount: items[0].value
                }
                const result = await machineSetUnitAmount(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置票额度
        async function ticketLimit() {
            try {
                const {id, items} = getParams()
                const params = {
                    id,
                    ticketTotalNumber: items[0].value,
                    ticketTotalAmount: items[1].value
                }
                const result = await machineSetTicketAmount(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 设置小包票张数
        async function setUnitTNumber() {
            try {
                const {id, items} = getParams()
                const params = {
                    id,
                    ticketFillUnitPackNumber: items[0].value
                }
                const result = await setUnitTicketNumber(params)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }

        // 恢复出票
        async function restoreDrawer() {
            try {
                const {id} = getParams()
                const result = await machineRefreshDrawBillStatus({id: id})
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 检查新大陆支付参数标识
        async function checkPaymentParm() {
            try {
                const {id, ticketType} = getParams()
                const result = await checkPaymentParme({id, ticketType})
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }


        // 上报版本号
        async function uploadCopyRight() {
            try {
                const {no} = getParams()
                const result = await copyRight(no)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }

        // 设置LED灯
        async function lightSetting() {
            try {
                const {no, items} = getParams()
                const result = await setLED({deviceNo: no, status: items[0].value})
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }

        // 设置设备声音
        async function soundSetting() {
            try {
                const {no, items} = getParams()
                if (items[0].value < 0 || items[0].value > 10) {
                    showMessage('声音应为1-10之间数字，请修改后重试', 'wran')
                    return
                }
                const result = await setSound({deviceNo: no, volume: items[0].value})
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }

        // 设置数据卡号
        async function setDataCardNumber() {
            try {
                const {no, items} = getParams()
                const result = await machineSetDataCardNo({
                    deviceNo: no,
                    dataCardNo: items[0].value
                })
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 解绑设备
        async function unbind() {
            try {
                const { no } = getParams()
                const result = await machineUnbind(no)
                setSuccess(result)
            } catch(e) {
                console.error(e)
            }
        }
        // 弹出框确定事件
        async function drawdownConfirm() {
            try {
                const name = state.currentDrawdown.prop
                switch(name) {
                    case 'restoreDrawer':
                        await restoreDrawer()
                        break
                    case 'dropOperators':
                        await dropOperators()
                        break
                    // case 'putaway':
                    //     await putaway()
                    //     break
                    case 'machineCashPldge':
                        await machineCashPldge()
                        break
                    case 'triggleFill':
                        await triggleFill()
                        break
                    case 'ticketLimit':
                        await ticketLimit()
                        break
                    case 'newMainLandParamInfo':
                        await newMainLandParamInfo()
                        break
                    case 'webOfThingsTripleInfo':
                        await webOfThingsTripleInfo()
                        break
                    case 'ticketUnitAmount':
                        await setTicketUnit()
                        break
                    case 'checkPaymentParm':
                        await checkPaymentParm()
                        break
                    case 'dataCardNo':
                        await setDataCardNumber()
                        break
                    case 'ticketTypes':
                        await setTicketType()
                        break
                    case 'AgentIds':
                        await setAgentId()
                        break
                    case 'storeId':
                        await setStoreId()
                        break
                    case 'replacementerId':
                        await setReplacementerId()
                        break
                    case 'agentBindAgentId':
                        await agentBindAgentId()
                        break
                    case 'unitTicketNumber':
                        await setUnitTNumber()
                        break
                    case 'firmwareUpgrade':
                        await firmwareUpgrade()
                        break
                    case 'unbind':
                        await unbind()
                        break
                    // // uploadCopyRight lightSetting soundSetting
                    case 'uploadCopyRight':
                        await uploadCopyRight()
                        break
                    case 'lightSetting':
                        await lightSetting()
                        break
                    case 'soundSetting':
                        await soundSetting()
                        break
                    case 'ticketRevise':
                        await ticketRevise()
                }
            } catch(e) {
                console.error(e)
            }
        }
        async function batchSetting(e) {
            try {
               const result = await setPrams(e)
               if (isValid(result)) {
                    message.success('设置成功！')
                    loadData()
                }
               
            } catch(e) {
                console.error(e)
            }
        }
        return {
            ...toRefs(state),
            detail,
            loadData,
            dropEVent,
            drawdownConfirm,
            batchSetting
        }
    }
}
</script>
